import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CreateChatReportDto } from '@pixels/universal/model/chat/chat-report/dto/create-chat-report.dto';
import { ChatReport } from '@pixels/universal/model/chat/chat-report/schema/chat-report.schema';
import { ChatRoomCover } from '@pixels/universal/model/chat/chat-room/schemas/chat-room.schema';
import { CreateQuestionDto } from '@pixels/universal/model/chat/question/dto/create-question.dto';
import { Question } from '@pixels/universal/model/chat/question/schema/question.schema';
import { BasicLoadMoreResult, LoadMoreParam } from '@pixels/universal/model/paging-model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TalkPlusApiService {
  private readonly httpClient = inject(HttpClient);

  public createQuestion(questionDto: CreateQuestionDto): Observable<Question> {
    return this.httpClient.post<Question>(`/questions`, questionDto);
  }

  public fetchMyQuestions(loadMoreParam: LoadMoreParam): Observable<BasicLoadMoreResult<Question>> {
    return this.httpClient.get<BasicLoadMoreResult<Question>>(`/questions/my`, { params: loadMoreParam as any });
  }

  public fetchMyOneQuestions(id: string): Observable<Question> {
    return this.httpClient.get<Question>(`/questions/my/${id}`);
  }

  public fetchOneChatRoomForPublic(chatRoomId: string): Observable<ChatRoomCover> {
    return this.httpClient.get<ChatRoomCover>(`/chat-rooms/${chatRoomId}/public`);
  }

  public report(params: CreateChatReportDto): Observable<ChatReport> {
    return this.httpClient.post<ChatReport>(`/chat-reports`, params);
  }
}
